<template>
  <div class="content">
    <base-alert icon="tim-icons icon-lock-circle" v-if="this.$store.state.user.demo" type="warning" dismissible>
      {{  $t('search.demo.disabledFeatures') }}
    </base-alert>
    <div class="row">
      <div class="col-md-6">
        <card class="stacked-form" title="User Details">
          <h4 slot="header" class="card-title"> {{ $t('members.add') }}
            <b>{{ this.$store.getters.getCurrentOrganisation.name }}</b></h4>
          <p> {{ $t('members.addExplaination') }}</p>
          <br>
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(submit)" autocomplete="off">
              <div>
                <ValidationProvider :name="$t('input.email')" rules="required|email" v-slot="{ passed, failed, errors }">
                  <base-input
                    required
                    v-model="new_user"
                    :placeholder="$t('input.email')"
                    addon-left-icon="tim-icons icon-email-85"
                    :error="errors[0]"
                    :class="[{ 'has-success': passed }, { 'has-danger': failed }]"
                    type="email"
                  ></base-input>
                </ValidationProvider>
                <base-button native-type="submit" class="mt-3" type="primary">
                {{ $t('members.btnInvite') }}
                </base-button>
              </div>
            </form>
          </ValidationObserver>
        </card>
      </div>
    </div>
    <div class="col-md-12">
      <card class="card-plain" body-classes="table-full-width">
        <h2> {{ $t('members.list') }}</h2>
        <el-table
          header-cell-class-name="table-transparent"
          header-row-class-name="table-transparent"
          row-class-name="table-transparent"
          :data="tableData"
          id="mainarr"
        >
          <el-table-column
            min-width="200"
            sortable
            :label="$t('members.email')"
            property="email"
          ></el-table-column>
          <el-table-column
            min-width="150"
            sortable
            :label="$t('members.role')"
            property="role"
          ></el-table-column>
          <el-table-column
            min-width="100"
            sortable
            :label="$t('members.isInvitationAccepted')"
            property="is_invitation_accepted"
          ></el-table-column>
          <el-table-column
            min-width="150"
            sortable
            :label="$t('members.invitedDate')"
            property="invited"
          ></el-table-column>
          <el-table-column :min-width="135" align="right" :label="$t('members.actions')">
            <div slot-scope="props">
              <base-button
                @click.native="handleDelete(props.$index, props.row)"
                class="remove btn-link"
                type="danger"
                size="sm"
                icon
              >
                <i class="tim-icons icon-simple-remove"></i>
              </base-button>
            </div>
          </el-table-column>
        </el-table>
      </card>
    </div>
  </div
  >
</template>
<script>
import {Table, TableColumn} from 'element-ui';
import {refreshContext} from "../../utils";
import swal from "sweetalert2";
import { formatDate } from '../../utils';
import {extend} from 'vee-validate';
import {required, email, min} from 'vee-validate/dist/rules';
import { mapMutations } from 'vuex';
import {BaseAlert} from 'src/components';

extend('email', email);
extend('min', min);
extend('required', required);

export default {
  components: {
    BaseAlert,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  mounted() {
    this.$store.subscribe((mutation, state) => {
      switch(mutation.type) {
        case 'switchOrganisation':
          this.getUsers();
        break;
      }
    })
    this.getUsers();
    this.getDomains();
  },
  data() {
    return {
      tableData: [],
      new_user: null,
      errors: [],
      domains: ["online.de", "alice.it", "virgilio.it", "tin.it", "tim.it", "aol.com", "aol.fr", "me.com", "mac.com", "icloud.com", "arcor.de", "bluewin.ch", "blueyonder.co.uk", "bbox.fr", "btinternet.com", "comcast.net", "email.it", "facebook.com", "free.fr", "aliceadsl.fr", "infonie.fr", "libertysurf.fr", "online.fr", "freesbee.fr", "alicepro.fr", "worldonline.fr", "freenet.de", "gmx.de", "gmx.net", "gmx.at", "caramail.com", "gmx.fr", "gmail.com", "googlemail.com", "home.nl", "laposte.net", "libero.it", "blu.it", "giallo.it", "mail.ru", "bk.ru", "hotmail.com", "live.com", "msn.com", "outlook.com", "windowslive", "dbmail.com", "hotmail.fr", "live.fr", "msn.fr", "hotmail.be", "msn.be", "live.be", "hotmail.de", "hotmail.it", "hotmail.co.uk", "hotmail.es", "live.co.uk", "live.it", "live.nl", "live.se", "live.de", "hotmail.nl", "outlook.fr", "hotmail.se", "live.dk", "live.com.pt", "telefonica.es", "movistar.es", "numericable.fr", "noos.fr", "o2.pl", "orange.fr", "wanadoo.fr", "skynet.be", "rambler.ru", "lenta.ru", "autorambler.ru", "myrambler.ru", "ro.ru", "r0.ru", "sfr.fr", "neuf.fr", "9online.fr", "9business.fr", "cegetel.net", "club-internet.fr", "cario.fr", "guideo.fr", "mageos.com", "fnac.net", "waika9.com", "sky.com", "telenet.be", "tiscali.it", "tiscali.co.uk", "t-online.de", "verizon.net", "ono.com", "voila.fr", "web.de", "wp.pl", "yahoo.com", "ymail", "rocketmail", "yahoo.fr", "yahoo.co.uk", "yahoo.es", "yahoo.de", "yahoo.it", "ymail.com", "yahoo.com.tw", "rocketmail.com", "yahoo.se", "yandex.ru", "mail.com", "talktalk.net"]
    };
  },
  methods: {
    ...mapMutations([
      'switchOrganisation', // map `this.increment()` to `this.$store.commit('increment')`
    ]),
    getUsers() {
      let accessToken = localStorage.getItem("userToken");

      fetch(this.$apiEndpoint + "/api/orgs/" + this.$store.getters.getCurrentOrganisation.id + "/users", {
        method: "GET",
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        }
      }).then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return new Error("Cannot fetch context");
        }
      }).then((data) => {
        let returnTable = [];
        data.forEach(d => {
          returnTable.push({
            "id": d.id,
            "username": d.username,
            "email": d.email,
            // "email": "damien.lescos@sitincloud.com",
            "invited": formatDate(d.invited),
            "enabled": (d.enabled ? <i class="tim-icons icon-check-2"></i> : <i class="tim-icons icon-simple-remove"></i>),
            "is_invitation_accepted": (d.is_invitation_accepted ? <i class="tim-icons icon-check-2"></i> : <i class="tim-icons icon-simple-remove"></i>),
            "role": d.role,
          });
        });
        this.tableData = returnTable;
      });
    },
    submit() {
      this.inviteUser();
    },
    inviteUser() {
      if (!this.$store.state.user.demo && this.checkEmail()) {
        let accessToken = localStorage.getItem("userToken");

        fetch(this.$apiEndpoint + "/api/orgs/" + this.$store.getters.getCurrentOrganisation.id + "/users/invite/" + this.new_user, {
          method: "POST",
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          }
        }).then((response) => {
          console.log(response);
          if (response.ok) {
            refreshContext(this.$store);
            swal.fire({
              icon: 'success',
              title: 'Success',
              text: this.$t('members.memberInvited'),
            });
            return response.json();
          } else {
            response.json().then((errorResponse) => {
              swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: this.$t("errors.backend."+errorResponse.detail),
              });
            });
            throw new Error(errorResponse.detail);
          }
        }).then((data) => {
          this.tableData.push({
            "id": data["user"].id,
            "username": data["user"].username,
            "email": data["user"].email,
            // "email": "damien.lescos@sitincloud.com",
            "enabled": data["user"].enabled,
            "invited": formatDate(data["user"].invited),
            // "is_invitation_accepted": data["user"].is_invitation_accepted,
            "is_invitation_accepted": (data["user"].is_invitation_accepted ? <i class="tim-icons icon-check-2"></i> : <i class="tim-icons icon-simple-remove"></i>),
            "role": data["user"].role,
          });
        })
        .catch((error) => {
          console.error(error);
        });
      }
    },
    isAnyFreeDomain(domains, email) {
      return domains.some((domain) => {
        return email.endsWith("@"+domain);
      });
    },
    getDomains() {
      const fetchOptions = {
        method: "GET",
      };
      const apiUrl = `https://raw.githubusercontent.com/disposable/disposable-email-domains/master/domains.txt`;
      fetch(apiUrl, fetchOptions)
        .then((response) => {
          if (response.ok) {
            return response.text();
          } else {
            throw new Error("Unauthorized resource");
          }
        })
        .then((data) => {
          data.split("\n").forEach(d => {
            this.domains.push(d)
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'danger',
            message: this.$t('errors.error') + ` - ${error.message}...`,
            icon: 'tim-icons icon-bell-55'
          });
        });
    },
    checkEmail() {
      console.log(this.domains)
      console.log(this.new_user)
      if (this.isAnyFreeDomain(this.domains, this.new_user)) {
        swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: this.$t("register.notCompanyEmail"),
          customClass: {
            confirmButton: 'btn btn-danger btn-fill',
          },
          buttonsStyling: false,
        });
        return false;
      }
      return true;
    },
    handleDelete(index, row) {
      swal.fire({
        title: this.$t('search.popup.confrm'),
        text: this.$t('search.popup.cantRevert'),
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        confirmButtonText: 'Yes, delete it!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          // this.deleteRow(row);
          this.removeUser(index, row);
        }
      });
    },
    removeUser(index, row) {
      let accessToken = localStorage.getItem("userToken");
      const fetchOptions = {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      const apiUrl = this.$apiEndpoint + `/api/orgs/` + this.$store.getters.getCurrentOrganisation.id + `/users/` + row.id;
      fetch(apiUrl, fetchOptions)
        .then((response) => {
          if (response.ok) {
            this.tableData.splice(index, 1);
            swal.fire({
              title: this.$t('alerts.deleted'),
              text: this.$t('alerts.youDeleted') + " " + row.email,
              icon: 'success',
              confirmButtonClass: 'btn btn-success btn-fill',
              buttonsStyling: false
            });
            return response.json();
          } else if (response.status === 401) {
            this.logout();
            throw new Error("Accès non autorisé. Veuillez-vous connecter.");
          } else {
            response.json().then((errorResponse) => {
              swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: this.$t("errors.backend."+errorResponse.detail),
              });
            });
            throw new Error(errorResponse.detail);
          }
        })
        .catch((error) => {
          console.error("Error removing user:", error);
        });
      // if (row.id == this.$store.state.user.id) {
      //   this.$store.switchOrganisation(this.$store.state);
      // }
    },
    logout() {
      localStorage.removeItem('userToken'); // Adjust based on your storage method
      localStorage.removeItem('startDate');
      localStorage.removeItem('endDate');
      localStorage.removeItem('exclusions');
      this.$store.dispatch('logout'); // If using Vuex

      this.$router.push("/login");
    }
  }
};
</script>
<style>
.table-transparent {
  background-color: transparent !important;
}
</style>
