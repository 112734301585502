<template>
  <div>
    <base-button data-toggle="modal" data-target="#searchModal" link type="neutral" class="col-12" 
      @click="openFilters">
      <i class="tim-icons icon-pencil"></i>{{ $t('search.modal.searchAssistant') }}
    </base-button>

    <modal :show="isOpenFilters" class="modal-filter" id="searchModal" centered show-close
      headerClasses="justify-content-center" @close="closeFiltersModal" modalClasses="modal-60 modal-text">
      <h4 slot="header" class="title title-up modal-text">{{ $t('search.modal.idLeakSearchAssistant') }}</h4>
      <div class="extended-forms">
        <!-- <card title="Horizontal Form"> -->
        <h4 slot="header" class="card-title">{{ $t('search.modal.onlyOneInput') }}</h4>
        <form class="stacked-form" action="#" method="#" @submit.prevent>
          <div class="row">
            <label class="col-md-6 title">{{ $t('search.modal.findResults') }}</label>
            <label class="col-md-6 title">{{ $t('search.modal.toDoSearchInput') }}</label>
          </div>
          <div class="row">
            <div class="col-md-6">
              <base-input :label="$t('search.modal.domainName')" v-model="domainName" lowercase :disabled="email != ''" borderColor="#ed592e"></base-input>
            </div>
            <div class="col-md-6" style="align-content: center;">
              <label>{{ $t('search.modal.domainNameExample') }}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <base-input :label="$t('search.modal.email')" v-model="email" lowercase :disabled="domainName != ''" borderColor="#ed592e"></base-input>
            </div>
            <div class="col-md-6" style="align-content: center;">
              <label>{{ $t('search.modal.emailExample') }}</label>
            </div>
          </div>
        </form>
      <!-- </card> -->
      </div>
      <template slot="footer">
        <base-button type="primary" simple @click="resetFilters">{{ $t('search.modal.btnReset') }}</base-button>
        <base-button type="primary" @click="applyFilters">{{ $t('search.modal.btnApply') }}</base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import { Tag, DatePicker, Select, Option } from 'element-ui';
import { BaseInput } from 'src/components/index';
import Modal from "../../components/Modal.vue";

export default {
  name: 'telegram-assistant',
  components: {
    Modal,
    BaseInput,
    [DatePicker.name]: DatePicker,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tag.name]: Tag,
  },
  props: {
    propQuery: "",
  },
  data() {
    return {
      query: this.propQuery,
      email: "",
      domainName: "",
      isOpenFilters: false,
      escapedChars: ['+', '^', '`', ':', '{', '}', '"', '[', ']', '(', ')', '~', '!', '\\', '*', ' '],
    };
  },
  methods: {
    parseQuery() {
      this.email = "";
      this.domainName = "";
      if (this.propQuery.includes('@')) {
        if (this.propQuery.startsWith('@')) {
          this.domainName = this.propQuery.trim();
        } else {
          this.email = this.propQuery.trim();
        }
      } else {
        this.domainName = this.propQuery.trim();
      }
    },
    convertToQuery() {
      this.query = "";
      if (this.email) {
        this.query = this.email.trim();
      } else {
        if (this.domainName) {
          if (this.domainName.trim().startsWith('@')) {
            this.query = this.domainName.trim();
          } else {
            this.query = "@" + this.domainName.trim();
          }
        }
      }
    },
    openFilters() {
      this.parseQuery();
      this.isOpenFilters = true;
    },
    applyFilters() {
      this.convertToQuery();
      this.$emit('apply-filters', this.query);
      this.closeFiltersModal();
    },
    resetFilters() {
      this.email = "";
      this.domainName = "";
    },
    closeFiltersModal() {
      this.isOpenFilters = false;
    },
  }
};
</script>

<style>
.modal-60 {
  max-width: 60% !important;
}

.border-white {
  border-color: white !important;
}

.modal-text {
  color: white !important;
}
</style>