<template>
    <div>
        <el-tooltip content="Scroll to top" effect="light" :open-delay="300" placement="left" style="z-index: 999">
            <a v-show="goToTop" class="settings-icon bottom-right" style="z-index: 999">
                <i class="fa fa-angle-double-up fa-2x" @click="scrollToTop"></i>
            </a>
        </el-tooltip>
        <el-tooltip content="Scroll to bottom" effect="light" :open-delay="300" placement="left" style="z-index: 999">
            <a v-show="!goToTop" class="settings-icon bottom-right" style="z-index: 999">
                <i class="fa fa-angle-double-down fa-2x" @click="scrollToBottom"></i>
            </a>
        </el-tooltip>
    </div>
</template>

<script>
export default {
    name: "scroll-to-top-button",
    data() {
        return {
            goToTop: false
        }
    },
    methods: {
        scrollToTop() {
            this.goToTop = !this.goToTop;
            window.scrollTo(0, 0);
        },
        scrollToBottom() {
            var container = this.$el.parentElement;
            window.scrollTo(0, container.scrollHeight);
            this.goToTop = !this.goToTop;
        },
        scrollListener(e) {
            this.goToTop = window.scrollY > 150
        }
    },
    mounted() {
        window.addEventListener('scroll', this.scrollListener)
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.scrollListener)
    }
}
</script>

<style scoped>
.bottom-right {
    position: fixed;
    bottom: 80px;
    right: 40px;
    cursor: pointer;
}

.btn {
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.55);
    padding-top: 27px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
}
</style>

