<template>
  <div class="content">
    <div class="row">
      <router-link class="nav-item list-unstyled" tag="li" :to="{ name: 'Search', params: { query: query, telegram: telegram } }">
        <a class="nav-link text-primary">
          <i class="tim-icons icon-minimal-left"></i>{{ $t('search.newSearch') }}
          <!-- {{ $t('searchResults.goBack') }} -->
        </a>
      </router-link>
    </div>
    <div class="container">
      <scroll-to-top-button-plugin></scroll-to-top-button-plugin>
      <div class="row justify-center">
        <div class="loading-container" v-if="loading">
          <div class="loading-text">{{ $t('search.loading') }}</div>
        </div>
        <div class="spinner-container" v-if="loading">
          <div class="spinner"></div>
        </div>
        <div class="row col-12" v-if="(searchQuery || telegram) && !loading">
          <tabs type="primary" class="col-12" :activeTab="activeTab">
            <tab-pane v-if="query.length > 0" label="Tor">
              <div v-if="error" class="error">Error: {{ error }}</div>
              <div class="row" v-if="searchQuery && !loading">
                <div class="col">
                  <div v-if="searchPerformed && (totalDarknetTorResults === 0)" class="no-results-found">
                    {{ $t('search.noResult') }} "{{ searchQuery }}"
                  </div>
                  <div v-if="searchQuery && !loading && totalDarknetTorResults" class="row search-result-message">
                    <div class="col-7">
                      <div>
                        <span v-if="totalDarknetTorResults < 10000" class="search-result-count">{{
                          totalDarknetTorResults.toLocaleString() }}</span>
                        <span v-if="totalDarknetTorResults >= 10000" class="search-result-count">{{ $t('search.moreThan')
                        }} {{ (10000).toLocaleString() }}</span>
                        <span class="search-result-text"> {{ $t('search.results') }} "</span>
                        <span class="search-result-query">{{ searchQuery }}</span>
                        <span class="search-result-text">".</span>
                      </div>
                      <p>{{ $t('search.displayed') }} {{ darknetTorResults.hits.length }}</p>
                    </div>
                    <div class="col-7" v-if="!currentFilteredUrl">
                      <a href="#/search-results" @click="filterDarknetTorResults()">
                        <span class="badge badge-filter badge-primary"><b>{{ $t('search.allCategories') }}</b> ({{
                          darknetTorResults.hits.length }})</span>
                      </a>
                      &nbsp;-&nbsp;
                      <a v-for="(item, index) in darknetTorLevels" :key="item.name + index"
                        href="#/search-results" @click="filterDarknetTorResults(item.name)">
                        <span :class="'badge badge-filter badge-' + item.type"><b>{{ item.name }}</b> ({{ item.count
                        }})</span>&nbsp;
                      </a>
                    </div>
                    <div class="col-5" align="right">
                      <div class="row">
                        <base-button style="padding-top: 8px;padding-bottom: 8px;margin-bottom: 12px;margin-top: 20px;"
                          type="info" class="btn-sm" v-if="currentFilteredUrl"
                          @click.native="resetToGlobalDarknetTorResults">
                          <i class="tim-icons icon-minimal-left"></i>{{ $t('search.backResultsList') }}
                        </base-button>
                        <ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">
                          <base-dropdown tag="li" :menu-on-right="!$rtl.isRTL" title-tag="a" class="nav-item"
                            title-classes="nav-link" menu-classes="dropdown-navbar dd-front">
                            <template slot="title">
                              <div class="btn btn-primary circle">
                                <span class="centered-text"><i class="tim-icons icon-cloud-download-93"></i></span>
                              </div>
                            </template>
                            <li v-for="report in darknetReports" class="nav-link">
                              <a @click="handleDownload(report.id)" class="nav-item dropdown-item">{{ report.name }}</a>
                            </li>
                          </base-dropdown>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="currentFilteredUrl" class="row result-for-url-header">
                <div class="col">
                  <el-tag type="primary" label close @click="resetToGlobalDarknetTorResults"
                    @close="resetToGlobalDarknetTorResults">
                    {{ $t('search.results') }} {{ currentFilteredUrl }}
                  </el-tag>
                </div>
              </div>
              <card class="card" v-if="!item.checked" v-for="(item, index) in darknetTorResults.visible" :key="item.id"
                v-show="!item.checked">
                <div slot="header" class="col-12 d-flex">
                  <div class="col-10">
                    <div class="row">
                      <span :class="'badge badge-' + item.level0.type">{{ item.level0.name }}</span>
                      <span class="col-11 title">{{ item.title }}</span>
                    </div>
                    <div class="row">
                      <span class="title" style="color: #ed592e; width: 100%;" v-if="!item.checked" @click="openLink(item.url)"
                        @mouseover="item.hovered = true" @mouseleave="item.hovered = false">
                        <!-- {{ item.hovered ? item.url : shortenUrl(item.url) }} -->
                        {{ currentFilteredUrl === getParentUrl(item.url) ? item.hovered ? item.url : shortenUrl(item.url) : (item.hovered ? getParentUrl(item.url) : shortenUrl(getParentUrl(item.url))) }}
                      </span>
                      <p v-if="item.file && item.file.length > 1 && currentFilteredUrl === getParentUrl(item.url)">{{ item.file }}</p>
                    </div>
                  </div>
                  <div class="col-2" align="right">
                    <base-button type="primary" round icon v-if="!item.checked" outlined
                      @click="copyToClipboard(item.url)">
                      <i class="fa fa-copy"></i>
                    </base-button>
                    <template v-if="!item.checked && getParentUrl(item.url) !== currentFilteredUrl">
                      <base-button type="primary" simple label outlined @click="showDarknetTorOccurrences(item.url)"
                        style="color: white">
                        {{ getDarknetTorOccurrences(item.url) }} x
                      </base-button>
                    </template>
                    <template v-if="!item.checked && currentFilteredUrl === getParentUrl(item.url)">
                      <base-button type="primary" simple style="color: white" label outlined>
                        {{ index + 1 }}/{{ totalDarknetTorFilteredOccurences }}
                      </base-button>
                    </template>
                  </div>
                </div>
                <!-- <p v-if="!item.checked" v-html="findTextAroundKeyword(item.text, searchQuery)"></p> -->
                <collapse :multiple-active="false" :active-index="-1">
                  <card>
                    <p v-if="!item.checked" v-html="findTextAroundKeyword(item.text, searchQuery)"></p>
                  </card>
                  <collapse-item :title="item.date + ' ' + $t('search.seeMore')">
                    <p v-if="!item.checked" v-html="fullTextWithKeyword(item.text, searchQuery)"></p>
                  </collapse-item>
                </collapse>
              </card>
              <div class="row pagination-row"
                v-if="searchQuery && !loading && !viewingDarknetTorOccurrences && darknetTorResults.hits.length < totalDarknetTorResults">
                <ul class="pagination">
                  <li>
                    <base-button type="primary" round @click="loadMoreContent">
                      {{ $t('search.loadMore') }}<i class="tim-icons icon-simple-add"></i>
                    </base-button>
                  </li>
                </ul>
              </div>
            </tab-pane>

            <tab-pane v-if="telegram.length > 0" label="Telegram" name="test" active>
              <div v-if="error" class="error">Error: {{ error }}</div>
              <div class="row" v-if="telegram && !loading">
                <div class="col">
                  <div v-if="searchPerformed && totalTelegramResults === 0" class="no-results-found">
                    {{ $t('search.noResult') }} "{{ telegram }}"
                  </div>
                  <div v-if="telegram && !loading && totalTelegramResults" class="row search-result-message">
                    <div class="col-7">
                      <div>
                        <span v-if="totalTelegramResults < 10000" class="search-result-count">{{
                          totalTelegramResults.toLocaleString() }}</span>
                        <span v-if="totalTelegramResults >= 10000" class="search-result-count">{{ $t('search.moreThan') }}
                          {{ (10000).toLocaleString() }}</span>
                        <span class="search-result-text"> {{ $t('search.results') }} "</span>
                        <span class="search-result-query">{{ telegram }}</span>
                        <span class="search-result-text">".</span>
                      </div>
                      <p>{{ $t('search.displayed') }} {{ telegramResults.hits.length }}</p>
                    </div>
                    <div class="col-5" align="right">
                      <div class="row">
                        <base-button style="padding-top: 8px;padding-bottom: 8px;margin-bottom: 12px;margin-top: 20px;"
                          type="info" v-if="currentFilteredTelegram" @click.native="resetToGlobalTelegramResults">
                          <i class="tim-icons icon-minimal-left"></i>{{ $t('search.backResultsList') }}
                        </base-button>
                        <ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">
                          <base-dropdown tag="li" :menu-on-right="!$rtl.isRTL" title-tag="a" class="nav-item"
                            title-classes="nav-link" menu-classes="dropdown-navbar dd-front">
                            <template slot="title">
                              <div class="btn btn-primary circle">
                                <span class="centered-text"><i class="tim-icons icon-cloud-download-93"></i></span>
                              </div>
                            </template>
                            <li v-for="report in telegramReports" class="nav-link">
                              <a @click="handleDownload(report.id)" class="nav-item dropdown-item">{{ report.name }}</a>
                            </li>
                          </base-dropdown>
                        </ul>
                      </div>
                    </div>
                    <!-- <div v-if="isFilterModified || dateFilterApplied || exclusions.length > 0"
               class="applied-filters">
            {{ $t('search.filtersApplied') }}
            <el-tag type="primary" v-for="(exclusion, index) in exclusions" :key="index" label closable icon small
                    @close="removeExclusion(index)">
              {{ exclusion }}
            </el-tag>
            <el-tag type="primary" v-if="startDate != '' && startDate != null" closable label @close="resetStartDate">
              {{ $t('search.filters.from') }} {{ formatDate(startDate) }}
            </el-tag>
            <el-tag type="primary" v-if="endDate != '' && endDate != null" closable label @close="resetEndDate">
              {{ $t('search.filters.until') }} {{ formatDate(endDate) }}
            </el-tag>
            <base-button v-if="isFilterModified" type="primary" simple round icon @click="restartSearch">
              <i class="tim-icons icon-refresh-01"></i>
            </base-button>
          </div> -->
                  </div>
                </div>
              </div>
              <div v-if="currentFilteredTelegram" class="row result-for-url-header">
                <div class="col">
                  <el-tag type="primary" label close @click="resetToGlobalTelegramResults"
                    @close="resetToGlobalTelegramResults">
                    {{ $t('search.results') }} {{ currentFilteredTelegram }}
                  </el-tag>
                </div>
              </div>
              <card class="card" v-if="!item.checked" v-for="(item, index) in telegramResults.visible" :key="item.id"
                v-show="!item.checked" min-width="1000px">
                <div slot="header" class="col-12 d-flex">
                  <div class="col-10">
                    <div class="row">
                      <span v-if="true" class="col-1 badge badge-primary">Telegram</span>
                      <span class="col-11 title">t.me/{{ item.channel }}/{{ item.message_id }}</span>
                    </div>
                    <div class="row">
                      <span class="title" style="color: #ed592e; width: 100%;" v-if="!item.checked" @mouseover="item.hovered = true"
                        @mouseleave="item.hovered = false">
                        {{ item.data }}
                      </span>
                    </div>
                  </div>
                  <div class="col-2" align="right">
                    <base-button type="primary" round icon v-if="!item.checked" outlined
                      @click="copyToClipboard(item.data)">
                      <i class="fa fa-copy"></i>
                    </base-button>
                    <template v-if="!item.checked && item.data !== currentFilteredTelegram">
                      <base-button type="primary" simple label outlined @click="showTelegramOccurrences(item.data)"
                        style="color: white">
                        {{ getTelegramOccurrences(item.data) }} x
                      </base-button>
                    </template>
                    <template v-if="!item.checked && currentFilteredTelegram === item.data">
                      <base-button type="primary" simple style="color: white" label outlined>
                        {{ index + 1 }}/{{ totalTelegramFilteredOccurences }}
                      </base-button>
                    </template>
                  </div>
                </div>
                <div class="card-collapse">
                  <!-- <card>
                    <p>{{ item.channel }} / {{ item.message_id }}</p>
                  </card> -->
                  <div class="card card-plain">
                    <div class="card-header"><a data-toggle="collapse">{{ item.message_date }}</a></div>
                  </div>
                </div>
              </card>
              <div class="row pagination-row"
                v-if="searchQuery && !loading && !viewingTelegramOccurrences && telegramResults.hits.length < totalTelegramResults">
                <ul class="pagination">
                  <li>
                    <base-button type="primary" round @click="loadMoreContent">
                      {{ $t('search.loadMore') }}<i class="tim-icons icon-simple-add"></i>
                    </base-button>
                  </li>
                </ul>
              </div>
            </tab-pane>
          </tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Tag, Select, Option, DatePicker } from 'element-ui';
import { BaseAlert, BasePagination } from 'src/components';
import { refreshContext, formatDate, formatDateTime } from "@/utils";
import { mapMutations } from 'vuex';
import { Slider } from "src/components";
import ClipboardJS from "clipboard";
import FilterSearchButton from "./FilterSearchButtonPlugin.vue";
import swal from 'sweetalert2';
import { TabPane, Tabs, Collapse, CollapseItem, TagsInput } from 'src/components';
import ScrollToTopButtonPlugin from './ScrollToTopButtonPlugin.vue';


export default {
  name: "search-results",
  components: {
    BaseAlert,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
    Slider,
    [Tag.name]: Tag,
    FilterSearchButton,
    Collapse,
    CollapseItem,
    TagsInput,
    Tabs,
    TabPane,
    ScrollToTopButtonPlugin,
  },
  data() {
    return {
      token: localStorage.getItem("userToken") || "",
      files: [],
      query: this.$route.params.query || "",
      telegram: this.$route.params.telegram || "",
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchedData: [],
      upload: null,
      startDate: null,
      endDate: null,
      highScores: false,
      notVerified: false,
      voyageImpossible: false,
      clientNum: null,
      clientName: null,
      fraudAmount: 0.00,
      rangeScores: [0, 100],
      searchResultsFiles: [],
      isOpenFilters: false,
      isFilterModified: false,
      wordsLimit: 20,
      shortcuts: [
        {
          text: 'Today',
          onClick(picker) {
            picker.$emit('pick', new Date())
          }
        },
        {
          text: 'Yesterday',
          onClick(picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', date)
          },
        },
        {
          text: 'A week ago',
          onClick(picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', date)
          },
        },
        {
          text: 'A month ago',
          onClick(picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', date)
          },
        },
      ],
      search: "",
      darknetTorResults: {
        hits: [],
        visible: [],
      },
      telegramResults: {
        hits: [],
        visible: [],
      },
      errors: [],
      currentFilteredUrl: "",
      currentFilteredTelegram: "",
      totalDarknetTorFilteredOccurences: 0,
      loading: false,
      error: null,
      searchQuery: "",
      showHomePage: true,
      searchPerformed: false,
      groupedDarknetTorResults: new Map(),
      groupedTelegramResults: new Map(),
      totalDarknetTorResults: 0,
      totalTelegramResults: 0,
      exclusions: JSON.parse(localStorage.getItem("exclusions")) || [],
      currentPage: 1,
      viewingDarknetTorOccurrences: false,
      viewingTelegramOccurrences: false,
      startDate: localStorage.getItem("startDate") || "",
      endDate: localStorage.getItem("endDate") || "",
      dateFilter: "",
      maxVisiblePages: 1,
      start_offset: 0,
      maxHits: 500,
      token: localStorage.getItem("userToken") || "",
      timerCount: 30,
      isFilterModified: false,
      loadMore: false,
      consume: 0,
      darknetReports: [],
      telegramReports: [],
      darknetTorLevels: {},
      activeTab: "",
    };
  },
  mounted() {
    refreshContext(this.$store);
    this.checkUrlParameter();
    this.startSearch();
    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case 'switchOrganisation':
          this.pagination.currentPage = 1;
          this.searchedData = [];
          break;
      }
    });
  },
  methods: {
    ...mapMutations([
      'switchOrganisation', // map `this.increment()` to `this.$store.commit('increment')`
    ]),
    checkUrlParameter() {
      let url_str = window.location.toString()
      let url = new URL(url_str.replace("/#/", "/"))
      let params = new URLSearchParams(url);
      params = new URLSearchParams(url.search);
      // if (params) {
      //   this.routerReturn = "?" + params;
      // }
      // const query = params.get('query');
      // if (query) {
      //   this.query = query
      // }
      // const telegram = params.get('telegram');
      // if (telegram) {
      //   this.telegram = telegram
      // }
      const queryParams = new URLSearchParams(window.location.search);
      const code = params.get('code');
      if (code === '42') {
        this.$notify({
          message:
            this.$t('buy.paymentintent_successful'),
          timeout: 30000,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success'
        });
      }
      this.removeUrlParameter();
    },
    removeUrlParameter() {
      const url = new URL(window.location);
      url.searchParams.delete('query');
      url.searchParams.delete('telegram');
      url.searchParams.delete('code');
      window.history.pushState({}, '', url);
    },
    required(v) {
      return !!v || "Field is required";
    },
    async performSearch() { //startSearch
      this.searchQuery = this.query;
      this.search = this.query;
      this.timerCount = 30;
      if (this.search.trim() || this.telegram.trim()) {
        let searchWords = this.search.split(/\s+/);
        if (searchWords.length > this.wordsLimit) {
          this.search = searchWords.slice(0, this.wordsLimit).join(" ");
        }
        let telegramWords = this.telegram.split(/\s+/);
        if (telegramWords.length > this.wordsLimit) {
          this.telegram = telegramWords.slice(0, this.wordsLimit).join(" ");
        }
        let dateFilter = "";
        if (this.startDate != "" && this.startDate != null) {
          dateFilter += " date:>=" + this.startDate;
        }
        if (this.endDate != "" && this.endDate != null) {
          dateFilter += " date:<=" + this.endDate;
        }
        if (this.search != this.searchQuery || dateFilter != this.dateFilter || this.isFilterModified || !this.loadMore) {
          this.resetSearch();
        }
        if (this.consume == 1) {
          if ((this.$store.getters.getCurrentOrganisation.tokens_q <= 0 && !this.$store.state.user.demo)
          || (this.$store.state.user.demo && this.$store.state.user.demo_queries <= 0 && this.$store.state.user.learning_queries <= 0)) {
            swal.fire({
              title: this.$t("search.popup.insufficientBalance"),
              text: this.$t("search.popup.pleaseRecharge"),
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-error btn-fill'
              },
              icon: 'error'
            });
            console.log("Insufficient balance");
            this.$router.push({name: "Search", params: { query: this.query, telegram: this.telegram } });
            return;
          }
        }
        this.dateFilter = dateFilter;
        this.showHomePage = false;
        this.searchPerformed = true;
        this.searchQuery = this.search;
        this.loading = true;
        this.error = null;
        this.loadMore = false;
        this.isFilterModified = false;
        const fetchOptions = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${this.token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: this.searchQuery + dateFilter,
            telegram: this.telegram, // + dateFilter,
            max_hits: this.maxHits,
            start_offset: this.start_offset,
            org_id: this.$store.getters.getCurrentOrganisation.id,
          }),
        };
        const apiUrl = this.$apiEndpoint + `/api/search`;
        try {
          await fetch(apiUrl, fetchOptions)
            .then((response) => {
              if (response.ok) {
                if (response.status === 204) {
                  swal.fire({
                    title: this.$t("search.popup.firstSearch"),
                    text: this.$t("search.popup.notDebited"),
                    buttonsStyling: false,
                    customClass: {
                      confirmButton: 'btn btn-info btn-fill'
                    },
                    icon: 'info'
                  });
                }
                return response.json();
              } else if (response.status === 401) {
                this.logout();
                throw new Error("Unauthorized access. Please login.");
              } else if (response.status === 429) {
                throw new Error("Too Many Requests");
              } else {
                throw new Error("Unauthorized resource");
              }
            })
            .then((data) => {
              if (data) {
                if (Object.keys(data.darknet).length > 0) {
                  this.darknetReports.push(data.darknet.report);
                  data.darknet.hits.forEach(d => {
                    let name = ""
                    let type = ""
                    if (d.level0 == "shop" || d.level0 == "undefined") { type = "success"; name = "Shop"; }
                    else if (d.level0 == "links") { type = "light"; name = "Links"; }
                    else if (d.level0 == "illegal") { type = "dark"; name = "ILLEGAL"; }
                    else if (d.level0 == "btc") { type = "warning"; name = "BTC"; }
                    else if (d.level0 == "forum") { type = "default"; name = "Forum"; }
                    else if (d.level0 == "ransomware") { type = "danger"; name = "Ransomware"; }
                    else if (d.level0 == "other") { type = "secondary"; name = "Other"; }
                    // else if (d.level0 == "undefined") { type = "dark"; name = "Undefined";}
                    else { type = "primary"; name = d.level0; }
                    this.darknetTorResults.hits.push(
                      {
                        "date": d.date,
                        "file": d.file,
                        "hash": d.hash,
                        "text": d.text,
                        "url": d.url,
                        "type": d.type,
                        "level0": {
                          "value": d.level0,
                          "name": name,
                          "type": type,
                        },
                        "title": d.title,
                        "checked": false,
                        "hovered": false,
                      }
                    )
                    if (this.darknetTorLevels[name]) {
                      this.darknetTorLevels[name].count += 1;
                    } else {
                      this.darknetTorLevels[name] = {
                        "name": name,
                        "type": type,
                        "count": 1
                      }
                    }
                  });
                  this.groupResultsByURL(this.darknetTorResults.hits);
                  this.totalDarknetTorResults = data.darknet.num_hits;
                  // if (this.totalResults == 0) {
                  //   swal.fire({
                  //     title: `Search unsuccessful!`,
                  //     text: `Your account has not been debited.`,
                  //     buttonsStyling: false,
                  //     customClass: {
                  //       confirmButton: 'btn btn-info btn-fill'
                  //     },
                  //     icon: 'info'
                  //   });
                  // }
                } else {
                  this.activeTab = "Telegram";
                }
                if (Object.keys(data.telegram).length > 0) {
                  this.telegramReports.push(data.telegram.report);
                  data.telegram.hits.forEach(d => {
                    this.telegramResults.hits.push(
                      {
                        "channel": d.channel,
                        "data": d.data,
                        "message_date": formatDateTime(d.message_date),
                        "message_id": d.message_id,
                        "checked": false,
                        "hovered": false,
                      }
                    )
                  });
                  this.groupResultsByTelegram(this.telegramResults.hits);
                  this.totalTelegramResults = data.telegram.num_hits;
                } else {
                  this.activeTab = "Tor";
                }
              }
            })
            .catch((error) => {
              this.error = error.message;
            })
            .finally(() => {
              this.loading = false;
              this.consume = 0;
              refreshContext(this.$store);
            });
        } catch (error) {
          this.error = error.message;
        } finally {
          this.loading = false;
          this.consume = 0;
          refreshContext(this.$store);
        }
      } else {
        this.search = "";
        this.resetSearch();
      }
    },
    filterDarknetTorResults(level = "") {
      if (level == "") {
        this.darknetTorResults.visible = [...this.groupedDarknetTorResults.values()]
          .flat()
          .filter(
            (hit, index, self) =>
              index ===
              self.findIndex((t) => this.getParentUrl(t.url) === this.getParentUrl(hit.url))
          );
      } else {
        this.darknetTorResults.visible = [...this.groupedDarknetTorResults.values()]
          .flat()
          .filter(
            (hit, index, self) =>
              index ===
              self.findIndex((t) => this.getParentUrl(t.url) === this.getParentUrl(hit.url) && hit.level0.name === level)
          );
      }
    },
    showSwal() {
      const swalBeforeSearch = swal.mixin({
        customClass: {
          confirmButton: "btn btn-success btn-fill",
          cancelButton: "btn btn-danger btn-fill",
        },
        buttonsStyling: false,
      });
      swalBeforeSearch
        .fire({
          title: this.$t('search.popup.confirm'),
          text: this.$t('search.popup.consumeAToken'),
          confirmButtonText: this.$t('search.popup.btnConfirm'),
          cancelButtonText: this.$t('search.popup.btnCancel'),
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            const swalWithBootstrapButtons5 = swal.mixin({
              customClass: {
                confirmButton: "btn btn-success btn-fill",
              },
              buttonsStyling: false,
            });
            swalWithBootstrapButtons5.fire({
              title: this.$t('search.popup.launched'),
              text: this.$t('search.popup.searchIsLaunched'),
            });
            this.performSearch();
          } else {
            this.$router.push({name: "Search", params: { query: this.query, telegram: this.telegram } });
          }
        });
    },
    loadMoreContent() {
      this.start_offset += this.maxHits;
      this.loadMore = true;
      this.performSearch();
      this.$notify({
        type: 'primary',
        message: "Additional content found!",
        icon: 'tim-icons icon-light-3',
        timeout: 10000
      });
    },
    startSearch() {
      this.resetSearch();
      // TODO
      this.search = this.query
      this.search = this.search.replaceAll("@", "");
      if (this.search.trim() || this.telegram.trim()) {
        this.showSwal();
      }
    },
    removeExclusion(exclusion) {
      this.exclusions.splice(this.exclusions.indexOf(exclusion), 1);
      this.isFilterModified = true;
    },
    resetStartDate() {
      this.startDate = "";
      this.isFilterModified = true;
    },
    resetEndDate() {
      this.endDate = "";
      this.isFilterModified = true;
    },
    restartSearch() {
      this.isFilterModified = true;
      this.saveFilters();
      this.performSearch();
    },
    saveFilters() {
      localStorage.setItem("exclusions", JSON.stringify(this.exclusions));
      localStorage.setItem("startDate", this.startDate);
      localStorage.setItem("endDate", this.endDate);
    },
    applyFilters(exclusions, startDate, endDate) {
      this.isFilterModified = (JSON.stringify(this.exclusions) !== JSON.stringify(exclusions)) || this.startDate !== startDate || this.endDate !== endDate;
      this.exclusions = exclusions;
      this.startDate = startDate;
      this.endDate = endDate;
      this.saveFilters();
    },
    shortenDescription(description) {
      const MAX_LENGTH = 50;
      if (description.length > MAX_LENGTH) {
        const shortenedDescription = `${description.substring(
          0,
          10
        )}...${description.substring(description.length - 10)}`;
        return shortenedDescription;
      }
      return description;
    },
    shortenUrl(url) {
      const MAX_LENGTH = 94;
      if (url.length <= MAX_LENGTH) {
        return url;
      }
      const shortenedUrl = url.substr(0, 45) + "..." + url.substr(-45);
      return shortenedUrl;
    },
    getParentUrl(url) {
      let ret = url.toLowerCase();
      if (ret.includes('/')) {
        ret = ret.substring(0, ret.substring(0, ret.length - 1).lastIndexOf('/') + 1);
      }
      return ret;
    },
    groupResultsByURL(hits) {
      const groupedResults = new Map();
      hits.forEach((hit) => {
        const normalizedUrl = this.getParentUrl(hit.url); // hit.url.toLowerCase();
        const hitsList = groupedResults.get(normalizedUrl) || [];
        groupedResults.set(normalizedUrl, [...hitsList, hit]);
      });
      this.groupedDarknetTorResults = groupedResults;
      this.darknetTorResults.visible = hits.filter(
        (hit, index, self) =>
          index === self.findIndex((t) => this.getParentUrl(t.url) === this.getParentUrl(hit.url))
        // self.findIndex((t) => t.url.toLowerCase() === hit.url.toLowerCase())
      );
    },
    groupResultsByTelegram(hits) {
      const groupedResults = new Map();
      hits.forEach((hit) => {
        const hitsList = groupedResults.get(hit.data) || [];
        groupedResults.set(hit.data, [...hitsList, hit]);
      });
      this.groupedTelegramResults = groupedResults;
      this.telegramResults.visible = hits.filter(
        (hit, index, self) =>
          index ===
          self.findIndex((t) => t.data === hit.data)
      );
    },
    resetToGlobalTelegramResults() {
      this.currentFilteredTelegram = "";
      this.totalTelegramFilteredOccurences = 0;
      this.telegramResults.visible = [...this.groupedTelegramResults.values()]
        .flat()
        .filter(
          (hit, index, self) =>
            index ===
            self.findIndex((t) => t.data === hit.data)
        );
      this.searchPerformed = true;
      this.viewingTelegramOccurrences = false;
    },
    resetToGlobalDarknetTorResults() {
      this.currentFilteredUrl = "";
      this.totalDarknetTorFilteredOccurences = 0;
      this.darknetTorResults.visible = [...this.groupedDarknetTorResults.values()]
        .flat()
        .filter(
          (hit, index, self) =>
            index ===
            self.findIndex((t) => this.getParentUrl(t.url) === this.getParentUrl(hit.url))
        );
      this.searchPerformed = true;
      this.viewingDarknetTorOccurrences = false;
    },
    openLink(url) {
      window.open(url, "_blank");
    },
    getDarknetTorOccurrences(url) {
      const normalizedUrl = this.getParentUrl(url);
      if (this.groupedDarknetTorResults.has(normalizedUrl)) {
        return this.groupedDarknetTorResults.get(normalizedUrl).length;
      }
      return 0;
    },
    getTelegramOccurrences(telegram) {
      if (this.groupedTelegramResults.has(telegram)) {
        return this.groupedTelegramResults.get(telegram).length;
      }
      return 0;
    },
    findTextAroundKeyword(fullText, searchQuery, charsBeforeAfter = 100) {
      if (!searchQuery.trim()) {
        return fullText.substring(0, 2 * charsBeforeAfter) + "...";
      }
      let snippet = "";
      let keywords = searchQuery.split(" ");
      if (keywords.length > this.wordsLimit) {
        return fullText.substring(0, 2 * charsBeforeAfter) + "...";
      }
      for (let i = 0; i < keywords.length; i++) {
        let keyword = keywords[i].trim();
        if (keyword != "") {
          const escapedSearch = keyword.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");
          const regex = new RegExp(`(${escapedSearch})`, "ig");
          let match = regex.exec(fullText);
          if (match) {
            const startIndex = Math.max(match.index - charsBeforeAfter, 0);
            const endIndex = Math.min(
              match.index + match[0].length + charsBeforeAfter,
              fullText.length
            );
            snippet += startIndex > 0 ? "..." : "";
            snippet += fullText.substring(startIndex, endIndex).trim();
            snippet += endIndex < fullText.length ? "..." : "";
          } else {
            return fullText.substring(0, 2 * charsBeforeAfter) + "...";
          }
          snippet = snippet.replace(
            regex,
            `<span style="color: #ed592e;">$1</span>`
          );
        }
      }
      return snippet;
    },
    fullTextWithKeyword(fullText, searchQuery) {
      if (!searchQuery.trim()) {
        return fullText;
      }
      let snippet = "";
      let keywords = searchQuery.split(" ");
      if (keywords.length > this.wordsLimit) {
        return fullText;
      }
      for (let i = 0; i < keywords.length; i++) {
        let keyword = keywords[i].trim();
        if (keyword != "") {
          const escapedSearch = keyword.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");
          const regex = new RegExp(`(${escapedSearch})`, "ig");
          let match = regex.exec(fullText);
          if (match) {
            snippet = fullText;
          } else {
            return fullText;
          }
          snippet = snippet.replace(
            regex,
            `<span style="color: #ed592e;">$1</span>`
          );
        }
      }
      return snippet;
    },
    applyFilterByUrl(url) {
      const results = this.groupedDarknetTorResults.get(this.getParentUrl(url));
      if (results) {
        this.darknetTorResults.visible = results;
        this.currentFilteredUrl = this.getParentUrl(url);
        this.totalDarknetTorFilteredOccurences = results.length;
      } else {
        this.darknetTorResults.visible = [];
        this.currentFilteredUrl = "";
        this.totalDarknetTorFilteredOccurences = 0;
      }
    },
    applyFilterByTelegram(telegram) {
      const results = this.groupedTelegramResults.get(telegram);
      if (results) {
        this.telegramResults.visible = results;
        this.currentFilteredTelegram = telegram;
        this.totalFilteredOccurences = results.length;
      } else {
        this.telegramResults.visible = [];
        this.currentFilteredTelegram = "";
        this.totalFilteredOccurences = 0;
      }
    },
    showDarknetTorOccurrences(url) {
      const normalizedUrl = this.getParentUrl(url); //url.toLowerCase();
      if (this.groupedDarknetTorResults.has(normalizedUrl)) {
        this.currentFilteredUrl = normalizedUrl;
        this.darknetTorResults.visible = this.groupedDarknetTorResults.get(normalizedUrl);
        this.totalDarknetTorFilteredOccurences = this.darknetTorResults.visible.length;
        this.viewingDarknetTorOccurrences = true;
      } else {
        this.darknetTorResults.visible = [];
        this.currentFilteredUrl = "";
        this.totalDarknetTorFilteredOccurences = 0;
        this.viewingDarknetTorOccurrences = false;
      }
    },
    showTelegramOccurrences(telegram) {
      if (this.groupedTelegramResults.has(telegram)) {
        this.currentFilteredTelegram = telegram;
        this.telegramResults.visible = this.groupedTelegramResults.get(telegram);
        this.totalTelegramFilteredOccurences = this.telegramResults.visible.length;
        this.viewingTelegramOccurrences = true;
      } else {
        this.darknetTorResults.visible = [];
        this.currentFilteredUrl = "";
        this.totalTelegramFilteredOccurences = 0;
        this.viewingTelegramOccurrences = false;
      }
    },
    resetSearch() {
      this.searchQuery = "";
      this.totalDarknetTorResults = 0;
      this.totalTelegramResults = 0;
      this.currentPage = 1;
      this.darknetTorResults.visible = [];
      this.darknetTorResults.hits = [];
      this.loading = false;
      this.error = null;
      this.showHomePage = true;
      this.maxVisiblePages = 1;
      this.consume = 1;
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
      // const clipboard = new ClipboardJS(".clipboard-btn", {
      //   text: function () {
      //     return text;
      //   },
      // });
      // clipboard.on("success", function (e) {
      //   this.$notify({
      //     type: 'primary',
      //     message: "Copied to clipboard!",
      //     icon: 'tim-icons icon-satisfied'
      //   });
      //   console.log("Copied to clipboard:", e.text);
      //   e.clearSelection();
      // });
      // clipboard.on("error", function (e) {
      //   console.error("Error copying to clipboard:", e.text);
      // });
    },
    loadMoreDarknetTorContent() {
      this.start_offset += this.maxHits;
      this.loadMore = true;
      this.performSearch();
      this.$notify({
        type: 'primary',
        message: "Additional content found!",
        icon: 'tim-icons icon-light-3',
        timeout: 10000
      });
    },
    loadMoreTelegramContent() {
      this.start_offset += this.maxHits;
      this.loadMore = true;
      this.performSearch();
      this.$notify({
        type: 'primary',
        message: "Additional content found!",
        icon: 'tim-icons icon-light-3',
        timeout: 10000
      });
    },
    downloadFile(report) {
      this.token = localStorage.getItem("userToken") || "";
      const fetchOptions = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.token}`,
          // "Content-Type": "application/pdf",
        },
      };
      const apiUrl = this.$apiEndpoint + `/api/reports/` + report + `/xlsx`;
      fetch(apiUrl, fetchOptions)
        .then((response) => {
          if (response.ok) {
            return response.blob();
          } else if (response.status === 401) {
            this.logout();
            throw new Error("Accès non autorisé. Veuillez-vous connecter.");
          } else {
            throw new Error("Unauthorized resource");
          }
        })
        .then((data) => {
          var file = window.URL.createObjectURL(data);
          window.location.assign(file);
        })
        .catch((error) => {
          this.$notify({
            type: 'danger',
            message: this.$t('errors.error') + ` - ${error.message}...`,
            icon: 'tim-icons icon-bell-55'
          });
        });
    },
    handleDownload(report) {
      this.downloadFile(report);
      this.$notify({
        type: 'primary',
        message: "Téléchargement en cours...",
        icon: 'tim-icons icon-cloud-download-93'
      });
    },
    logout() {
      localStorage.removeItem('userToken'); // Adjust based on your storage method
      localStorage.removeItem('startDate');
      localStorage.removeItem('endDate');
      localStorage.removeItem('exclusions');
      this.$store.dispatch('logout'); // If using Vuex

      this.$router.push("/login");
    }
  }
};
</script>

<style>
.pagination-select,
.search-input {
  width: 200px;
}

.swal2-icon-content {
  font-size: inherit !important;
}

#mainarr th {
  background-color: unset;
}

.spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.spinner {
  border: 5px solid transparent;
  border-top: 5px solid #ee5a2f;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 0.7s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.dd-front {
  position: absolute !important;
}

.badge-filter {
  font-size: 0.8rem !important;
}
</style>
