<template>
  <div>
    <base-alert icon="tim-icons icon-bulb-63"
      v-if="(this.$store.state.user.demo && this.$store.state.user.demo_queries > 0)" type="success" dismissible>
      {{ $t('search.demo.demoQueriesLeft', { nb_queries: this.$store.state.user.demo_queries }) }}
    </base-alert>
    <base-alert icon="tim-icons icon-bulb-63"
      v-if="(this.$store.state.user.demo && this.$store.state.user.demo_queries <= 0 && this.$store.state.user.learning_queries > 0)"
      type="info" dismissible>
      {{ $t('search.demo.realQueriesLeft', { nb_queries: this.$store.state.user.learning_queries }) }}
    </base-alert>
    <div class="container">
      <div class="row justify-center">
        <div class="col-lg-12 col-sm-8 col-md-6">
          <div v-if="showHomePage" class="col center-container">
            <div class="home-page">
              <div class="home-page-items">
                <img
                  src="https://assets-global.website-files.com/65463160950bc3900a5fbc0f/65463390bfc56f4a35195fd7_OwlyScan%20Logo.svg"
                  alt="OwlyScan" class="home-page-image" />
                <p class="home-page-title">OwlyScan</p>
                <h3>{{ $t('search.subtitle') }}</h3>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <card type="plain" style="background-color: #ed592e;">
                <h4 class="card-title">{{ (this.$store.state.user.demo && this.$store.state.user.demo_queries > 0) ?
                  $t('search.darknetSearch') + ' - ' + $t('search.demo.title') : $t('search.darknetSearch') }}</h4>
                <base-input v-if="(this.$store.state.user.demo && this.$store.state.user.demo_queries > 0)">
                  <el-select v-model="keyword" class="select-white" name="keywords" filterable
                    :placeholder="$t('search.demo.select')" @change="getPrevisu">
                    <el-option v-for="k in keywords" class="select-default" :label="k" :value="k" :key="k"></el-option>
                  </el-select>
                </base-input>
                <base-input required v-model="search" :placeholder="$t('search.darknetPlaceholder')"
                  addon-left-icon="fa fa-search" borderColor="white"
                  :style="(this.$store.state.user.demo && this.$store.state.user.demo_queries > 0) ? '' : 'margin-top: 35px'"
                  @keyup.enter="startSearch" @keyup="getPrevisu">
                </base-input>
                <search-assistant :propQuery="search" @apply-filters="applyFiltersTorDarknet"
                  :style="(this.$store.state.user.demo && this.$store.state.user.demo_queries > 0) ? '' : 'margin-bottom: 35px'"></search-assistant>
              </card>
              <card style="background-color: #1d8cf8">
                <h4 class="card-title">{{ $t('search.idLeakSearch') }}</h4>
                <label v-if="(this.$store.state.user.demo && this.$store.state.user.demo_queries > 0)">{{
                  $t('search.demo.disabled') }}</label>
                <base-input required v-model="telegram" :placeholder="$t('search.telegramPlaceholder')"
                  addon-left-icon="fa fa-at" borderColor="white" bgColor="transparent" @keyup.enter="startSearch"
                  @keyup="getPrevisu"
                  :disabled="(this.$store.state.user.demo && this.$store.state.user.demo_queries > 0)">
                </base-input>
                <telegram-assistant :propQuery="telegram" @apply-filters="applyFiltersTelegram"></telegram-assistant>
              </card>
            </div>
            <div class="col-md-6">
              <card style="min-height: 400px">
                <h4 class="col-12 card-title">{{ $t('search.resultsPreview') }}</h4>
                <p class="col-12 card-title">{{ searchPrevisu }}{{ searchPrevisu && telegramPrevisu ? " - " : "" }}{{
                  telegramPrevisu }}</p>
                <!-- <div class="loading-container" v-if="loading">
                <div class="loading-text">{{ $t('search.loading') }}</div>
              </div>
              <div class="spinner-container" v-if="loading">
                <div class="spinner"></div>
              </div> -->
                <div v-if="!loading" class="col-12">
                  <span class="badge badge-primary badge-previsu"><b>Darknet-Tor</b> ({{ darknetResults }})</span>
                  <div class="col-1"></div>
                  <div class="col-11 ml-auto">
                    <span v-for="item in buckets" :class="'badge badge-previsu badge-' + item.type"><b>{{ item.name }}</b>
                      ({{
                        item.doc_count }})</span>
                  </div>
                  <br />
                  <span class="badge badge-info badge-previsu"><b>Telegram</b> ({{ telegramResults }})</span>
                </div>
                <!-- <p slot="footer" class="col-12 card-title">{{ $t('search.updatePreview') }}</p> -->
                <base-alert slot="footer" icon="tim-icons icon-bulb-63"
                  v-if="(this.darknetResults > 2000)" dismissible> {{ $t('search.lotOfResults') }}
                </base-alert>
              </card>
            </div>
            <div class="col-12">
              <card>
                <base-button class="col-sm-6 d-flex align-center justify-center col-md-6 ml-auto mr-auto" type="primary"
                  :disabled="search.trim() == '*' || telegram.trim() == '*' || (!search.trim() && !telegram.trim() && !keyword) || (darknetResults == 0 && telegramResults == 0)"
                  @click.native="startSearch"><i class="tim-icons icon-zoom-split"></i>
                  {{ $t('search.startSearch') }}
                </base-button>
              </card>
            </div>
          </div>
          <div v-if="showHomePage" class="col">
            <collapse :multiple-active="false" :active-index="-1">
              <collapse-item :title="$t('search.termsOfUse')">
                <p style="text-align: center;">{{ $t('search.disclaimer') }}</p>
              </collapse-item>
            </collapse>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Tag, DatePicker, Select, Option } from 'element-ui';
import { refreshContext, formatDate } from "@/utils";
import swal from 'sweetalert2';
import SearchAssistant from './SearchAssistant.vue';
import TelegramAssistant from './TelegramAssistant.vue';
import { TabPane, Tabs, Collapse, CollapseItem, TagsInput, BaseAlert } from 'src/components';


export default {
  name: "DarknetSearch",
  components: {
    [DatePicker.name]: DatePicker,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tag.name]: Tag,
    Collapse,
    CollapseItem,
    TagsInput,
    Tabs,
    TabPane,
    SearchAssistant,
    TelegramAssistant,
    BaseAlert,
  },
  mounted() {
    refreshContext(this.$store);
    this.checkUrlParameter();
    if (this.$store.state.user.demo && this.$store.state.user.demo_queries > 0) {
      this.getDemoKeywords();
    }
    this.getPrevisu();
    // this.notifyDemo();
  },
  data() {
    return {
      searchPrevisu: "",
      loading: false,
      error: null,
      search: this.$route.params.query || "",
      telegram: this.$route.params.telegram || "",
      telegramPrevisu: "",
      showHomePage: true,
      token: localStorage.getItem("userToken") || "",
      wordsLimit: 20,
      timerCount: 30,
      darknetResults: 0,
      telegramResults: 0,
      buckets: [],
      keyword: "",
      keywords: []
    };
  },
  methods: {
    notifyDemo() {
      if (this.$store.state.user.demo) {
        if (this.$store.state.user.demo_queries > 0) {
          this.$notify({
            type: 'success',
            message: this.$t('search.demo.demoQueriesLeft', { nb_queries: this.$store.state.user.demo_queries }),
            icon: 'tim-icons icon-bulb-63'
          });
        } else {
          this.$notify({
            type: 'info',
            message: this.$t('search.demo.realQueriesLeft', { nb_queries: this.$store.state.user.learning_queries }),
            icon: 'tim-icons icon-bulb-63'
          });
        }
      }
    },
    async getPrevisu() {
      this.timerCount = 30;
      if (this.search == "" && this.keyword == "") {
        this.darknetResults = 0;
        this.buckets = [];
      }
      if (this.search !== this.searchPrevisu || this.keyword !== this.searchPrevisu) {
        this.darknetResults = 0;
        this.buckets = [];
        this.searchPrevisu = (this.search + ' ' + this.keyword).trim();
        if ((this.search.trim() || this.keyword) && this.search.trim() != '*') {
          if (this.searchPrevisu.split(/\s+/).length > this.wordsLimit) {
            swal.fire({
              title: this.$t('search.popup.tooMuchWords'),
              text: this.$t("search.popup.exceededWordsLimit", { words_limit: this.wordsLimit }),
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-info btn-fill'
              },
              icon: 'error'
            });
            return;
          }
          this.loading = true;
          this.error = null;
          const fetchOptions = {
            method: "POST",
            headers: {
              Authorization: `Bearer ${this.token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              query: this.searchPrevisu,
              org_id: this.$store.getters.getCurrentOrganisation.id,
            }),
          };
          const apiUrl = this.$apiEndpoint + `/api/test-search`;
          await fetch(apiUrl, fetchOptions)
            .then((response) => {
              if (response.ok) {
                return response.json();
              } else if (response.status === 401) {
                this.logout();
                throw new Error("Unauthorized access. Please login.");
              } else if (response.status === 429) {
                throw new Error("Too Many Requests");
              } else {
                throw new Error("Unauthorized resource");
              }
            })
            .then((data) => {
              this.buckets = [];
              data.aggregations.channels.buckets.forEach(d => {
                let name = ""
                let type = ""
                if (d.key == "shop") { type = "success"; name = "Shop"; }
                else if (d.key == "links") { type = "light"; name = "Links"; }
                else if (d.key == "illegal") { type = "dark"; name = "ILLEGAL"; }
                else if (d.key == "btc") { type = "warning"; name = "BTC"; }
                else if (d.key == "forum") { type = "default"; name = "Forum"; }
                else if (d.key == "ransomware") { type = "danger"; name = "Ransomware"; }
                else if (d.key == "credentials") { type = "info"; name = "Credentials"; }
                else if (d.key == "other") { type = "secondary"; name = "Other"; }
                else if (d.key == "undefined") { type = "secondary"; name = "Undefined"; }
                else { type = "primary"; name = d.key; }
                this.buckets.push(
                  {
                    "doc_count": d.doc_count,
                    "key": d.key,
                    "type": type,
                    "name": name
                  }
                )
              });
              this.darknetResults = data.num_hits;
            })
            .catch((error) => {
              this.error = error.message;
            })
            .finally(() => {
              this.loading = false;
              refreshContext(this.$store);
            });
        }
      }
      if (this.telegram == "") {
        this.telegramResults = 0;
      }
      if (this.telegram !== this.telegramPrevisu) {
        this.telegramResults = 0;
        this.telegramPrevisu = this.telegram;
        if (this.telegram.trim() && this.telegram.trim() != '*') {
          if (this.telegram.split(/\s+/).length > this.wordsLimit) {
            swal.fire({
              title: this.$t('search.popup.tooMuchWords'),
              text: this.$t("search.popup.exceededWordsLimit", { words_limit: this.wordsLimit }),
              buttonsStyling: false,
              customClass: {
                confirmButton: 'btn btn-info btn-fill'
              },
              icon: 'error'
            });
            return;
          }
          this.loading = true;
          this.error = null;
          const fetchOptions = {
            method: "POST",
            headers: {
              Authorization: `Bearer ${this.token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              query: this.telegramPrevisu,
              org_id: this.$store.getters.getCurrentOrganisation.id,
            }),
          };
          const apiUrl = this.$apiEndpoint + `/api/test-search-telegram`;
          fetch(apiUrl, fetchOptions)
            .then((response) => {
              if (response.ok) {
                return response.json();
              } else if (response.status === 401) {
                this.logout();
                throw new Error("Unauthorized access. Please login.");
              } else if (response.status === 429) {
                throw new Error("Too Many Requests");
              } else {
                throw new Error("Unauthorized resource");
              }
            })
            .then((data) => {
              this.telegramResults = data.num_hits;
            })
            .catch((error) => {
              this.error = error.message;
            })
            .finally(() => {
              this.loading = false;
              refreshContext(this.$store);
            });
        }
      }
    },
    getDemoKeywords() {
      this.token = localStorage.getItem("userToken") || "";
      const fetchOptions = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      };
      const apiUrl = this.$apiEndpoint + `/api/demo-keywords`;
      fetch(apiUrl, fetchOptions)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else if (response.status === 401) {
            this.logout();
            throw new Error("Accès non autorisé. Veuillez-vous connecter.");
          } else {
            throw new Error("Unauthorized resource");
          }
        })
        .then((data) => {
          this.keywords = data;
        })
        .catch((error) => {
          this.$notify({
            type: 'danger',
            message: this.$t('errors.error') + ` - ${error.message}...`,
            icon: 'tim-icons icon-bell-55'
          });
        });
    },
    checkUrlParameter() {
      let url_str = window.location.toString()
      let url = new URL(url_str.replace("/#/", "/"))
      let params = new URLSearchParams(url);
      params = new URLSearchParams(url.search);
      // const query = params.get('query');
      // if (query) {
      //   this.search = query
      // }
      // const telegram = params.get('telegram');
      // if (telegram) {
      //   this.telegram = telegram
      // }
      const queryParams = new URLSearchParams(window.location.search);
      const code = params.get('code');
      if (code === '42') {
        this.$notify({
          message:
            this.$t('buy.paymentintent_successful'),
          timeout: 30000,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success'
        });
      }
      this.removeUrlParameter();
    },
    removeUrlParameter() {
      const url = new URL(window.location);
      url.searchParams.delete('query');
      url.searchParams.delete('telegram');
      url.searchParams.delete('code');
      window.history.pushState({}, '', url);
    },
    required(v) {
      return !!v || "Field is required";
    },
    startSearch() {
      let darknetSearch = "";
      if (this.$store.state.user.demo && this.$store.state.user.demo_queries > 0) {
        if (!this.keyword) {
          swal.fire({
            title: this.$t('search.demo.title'),
            text: this.$t("search.demo.selectKeywords"),
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-info btn-fill'
            },
            icon: 'info'
          });
          return;
        } else {
          darknetSearch = (' ' + this.keyword);
        }
      }
      if (this.$store.state.user.demo && this.$store.state.user.demo_queries <= 0 && this.$store.state.user.learning_queries <= 0) {
        swal.fire({
          title: this.$t('search.demo.title'),
          text: this.$t("search.demo.demoEnd"),
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-info btn-fill'
          },
          icon: 'warning'
        });
        return;
      }
      let propsQuery = "";
      let propsTelegram = "";
      if (this.search != "" || this.keyword != "") {
        let searchWords = this.search.split(/\s+/);
        let demoWords = darknetSearch.split(/\s+/);
        if (searchWords.length + demoWords.length > this.wordsLimit) {
          this.search = searchWords.slice(0, this.wordsLimit - demoWords.length).join(" ");
        }
        // props += 'query=' + (this.search + darknetSearch).trim();
        propsQuery = (this.search + darknetSearch).trim();
      }
      if (!this.$store.state.user.demo || (this.$store.state.user.demo_queries == 0 && this.$store.state.user.learning_queries > 0)) {
        if (this.telegram != "") {
          let telegramWords = this.telegram.split(/\s+/);
          if (telegramWords.length > this.wordsLimit) {
            this.telegram = telegramWords.slice(0, this.wordsLimit).join(" ");
          }
          // props += 'telegram=' + this.telegram;
          propsTelegram = this.telegram;
        }
      }
      if (propsQuery || propsTelegram) {
        this.$router.push({name: "SearchResults", params: {query: propsQuery, telegram: propsTelegram}});
      }
    },
    applyFiltersTorDarknet(query) {
      this.search = query;
      this.getPrevisu();
    },
    applyFiltersTelegram(query) {
      this.telegram = query;
      this.getPrevisu();
    },
    logout() {
      localStorage.removeItem('userToken'); // Adjust based on your storage method
      this.$store.dispatch('logout'); // If using Vuex

      this.$router.push("/login");
    }
  },
};
</script>

<style scoped>
.card:hover .title {
  color: #ed592e;
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: auto;
}

.home-page {
  color: white;
  text-align: center;
  /* margin: auto;
  position: relative;
  top: 5rem;
  margin-bottom: 50px; */
}

.home-page-image {
  width: 80px;
}

.home-page-title {
  font-size: 35px;
  font-weight: 600;
}

.home-page h1 {
  font-weight: lighter;
}

.badge-previsu {
  font-size: 0.875rem !important;
  display: table !important
}

.spinner-container {
  /* position: fixed; */
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: rgba(0, 0, 0, 0.5); */
}

.spinner {
  border: 5px solid transparent;
  border-top: 5px solid #ee5a2f;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 0.7s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
