<template>
  <div>
    <base-button data-toggle="modal" data-target="#searchModal" link type="neutral" class="col-12" 
      @click="openFilters">
      <i class="tim-icons icon-pencil"></i>{{ $t('search.modal.searchAssistant') }}
    </base-button>

    <modal :show="isOpenFilters" class="modal-filter" id="searchModal" centered show-close
      headerClasses="justify-content-center" @close="closeFiltersModal" modalClasses="modal-70 modal-text">
      <h4 slot="header" class="title title-up modal-text">{{ $t('search.modal.darknetSearchAssistant') }}</h4>
      <div class="extended-forms">
        <h4 slot="header" class="card-title">{{ $t('search.modal.allInputs') }}</h4>
        <form class="form-horizontal" action="#" method="#" @submit.prevent>
          <div class="row">
            <label class="col-md-6 title">{{ $t('search.modal.findResults') }}</label>
            <label class="col-md-6 title">{{ $t('search.modal.toDoSearchInput') }}</label>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group has-label">
                <label>{{ $t('search.modal.allTheseWords') }}</label>
                <tags-input :label="$t('search.modal.allTheseWords')" v-model="allTheseWords" lowercase borderColor="#ed592e"></tags-input>
              </div>
            </div>
            <div class="col-md-6" style="align-content: center;">
              <label>{{ $t('search.modal.allTheseWordsExample') }}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group has-label">
                <label>{{ $t('search.modal.oneOfTheseWords') }}</label>
                <tags-input v-model="oneOfTheseWords" lowercase borderColor="#ed592e"></tags-input>
              </div>
            </div>
            <div class="col-md-6" style="align-content: center;">
              <label>{{ $t('search.modal.oneOfTheseWordsExample') }}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group has-label">
                <label>{{ $t('search.modal.exactlyTheseWords') }}</label>
                <tags-input v-model="exactlyTheseWords" lowercase borderColor="#ed592e"></tags-input>
              </div>
            </div>
            <div class="col-md-6" style="align-content: center;">
              <label>{{ $t('search.modal.exactlyTheseWordsExample') }}</label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group has-label">
                <label>{{ $t('search.modal.notTheseWords') }}</label>
                <tags-input v-model="notTheseWords" lowercase borderColor="#ed592e"></tags-input>
              </div>
            </div>
            <div class="col-md-6" style="align-content: center;">
              <label>{{ $t('search.modal.notTheseWordsExample') }}</label>
            </div>
          </div>
        </form>
      <!-- </card> -->
      </div>
      <template slot="footer">
        <base-button type="primary" simple @click="resetFilters">{{ $t('search.modal.btnReset') }}</base-button>
        <base-button type="primary" @click="applyFilters">{{ $t('search.modal.btnApply') }}</base-button>
      </template>
    </modal>
  </div>
</template>

<script>
import { Tag, DatePicker, Select, Option } from 'element-ui';
import { TagsInput } from 'src/components/index';
import Modal from "../../components/Modal.vue";

export default {
  name: 'search-assistant',
  components: {
    Modal,
    TagsInput,
    [DatePicker.name]: DatePicker,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tag.name]: Tag,
  },
  props: {
    propQuery: "",
  },
  data() {
    return {
      query: this.propQuery,
      allTheseWords: [],
      oneOfTheseWords: [],
      exactlyTheseWords: [],
      notTheseWords: [],
      isOpenFilters: false,
      isFilterModified: false,
      escapedChars: ['+', '^', '`', ':', '{', '}', '"', '[', ']', '(', ')', '~', '!', '\\', '*', ' '],
      shortcuts: [
        {
          text: 'Today',
          onClick (picker) {
            picker.$emit('pick', new Date())
          }
        },
        {
          text: 'Yesterday',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', date)
          },
        },
        {
          text: 'A week ago',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', date)
          },
        },
        {
          text: 'A month ago',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', date)
          },
        },
      ]
    };
  },
  methods: {
    afterEndDate(time) {
      return time.getTime() > new Date(this.endDate)
    },
    beforeStartDate(time) {
      let beforeDate = new Date(this.startDate);
      beforeDate.setDate(beforeDate.getDate() - 1)
      return time.getTime() < beforeDate
    },
    parseQuery() {
      let words = this.propQuery.split(' ');
      this.allTheseWords = [];
      this.oneOfTheseWords = [];
      this.exactlyTheseWords = [];
      this.notTheseWords = [];
      words.forEach((w, index) => {
        if (w == 'OR') {
          if (this.oneOfTheseWords[this.oneOfTheseWords.length - 1] != words[index - 1] && words[index - 1] != "") {
            this.oneOfTheseWords.push(words[index - 1]);
            words[index - 1] = "";
          }
          this.oneOfTheseWords.push(words[index + 1]);
          words[index] = "";
          words[index + 1] = "";
        }
      });
      words.forEach((w, index) => {
        if (w.startsWith('-')) {
          this.notTheseWords.push(w.substring(1));
          words[index] = "";
        }
      });
      let start = false;
      let phrase = "";
      words.forEach((w, index) => {
        if (w.startsWith('"') && w.endsWith('"')) {
          start = false;
          this.exactlyTheseWords.push(w.substring(1, w.length-1));
          words[index] = "";
        } else {
          if (w.endsWith('"')) {
            start = false;
            phrase += (" " + w.substring(0, w.length-1))
            this.exactlyTheseWords.push(phrase);
            words[index] = "";
            phrase = "";
          }
          if (start) {
            phrase += (" " + w);
            // this.exactlyTheseWords.push(w);
            words[index] = "";
          }
          if (w.startsWith('"')) {
            start = true;
            phrase += w.substring(1);
            // this.exactlyTheseWords.push(w.substring(1));
            words[index] = "";
          }
        }
      });
      words.forEach((w, index) => {
        if (w != "") {
          this.allTheseWords.push(w)
        }
      });
    },
    convertToQuery() {
      this.query = "";
      this.allTheseWords.forEach((e, index) => {
        let w = e;
        // if (e.contains(" ")) {
        //   w = '"' + e + '" ';
        // }
        if (index < (this.allTheseWords.length - 1)) {
          w += ' '; // ' AND '
        }
        this.query += w;
      });
      if (this.oneOfTheseWords.length > 0) {
        this.query += " ";
      }
      this.oneOfTheseWords.forEach((e, index) => {
        let w = e;
        // if (e.contains(" ")) {
        //   w = '"' + e + '" ';
        // }
        if (index < (this.oneOfTheseWords.length - 1)) {
          w += ' OR ';
        }
        this.query += w;
      });
      if (this.exactlyTheseWords.length > 0) {
        this.query += " ";
      }
      if (this.exactlyTheseWords.length > 0) {
        this.query += '"';
      }
      this.exactlyTheseWords.forEach((e, index) => {
        let w = e;
        if (index < (this.exactlyTheseWords.length - 1)) {
          w += ' ';
        }
        this.query += w;
      });
      if (this.exactlyTheseWords.length > 0) {
        this.query += '"';
      }
      if (this.notTheseWords.length > 0) {
        this.query += " ";
      }
      this.notTheseWords.forEach((e, index) => {
        let w = '-' + e + '';
        // if (e.contains(" ")) {
        //   w = '"' + e + '" ';
        // }
        if (index < (this.notTheseWords.length - 1)) {
          w += ' ';
        }
        this.query += w;
      });
      this.query = this.query.trim();
    },
    openFilters() {
      this.parseQuery();
      this.isOpenFilters = true;
    },
    applyFilters() {
      this.convertToQuery();
      this.$emit('apply-filters', this.query);
      this.closeFiltersModal();
    },
    resetFilters() {
      this.allTheseWords = [];
      this.oneOfTheseWords = [];
      this.exactlyTheseWords = [];
      this.notTheseWords = [];
    },
    closeFiltersModal() {
      this.isOpenFilters = false;
    },
  }
};
</script>

<style>
.modal-70 {
  max-width: 70% !important;
}

.modal-text {
  color: white !important;
}
</style>