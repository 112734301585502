<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <card>
          <h3 slot="header" class="title"> {{ $t('org.profile') }}</h3>
          <div>
            <base-input
              type="text"
              label="Name"
              :disabled="false"
              v-model="name"
            >
            </base-input>
          </div>
          <div>
            <div style="display: flex; width: 100%;">
              <base-button class="btn-fill clipboard-btn" @click="copyToClipboard(name)">
                {{ $t('general.clipboard') }}
              </base-button>
              <base-button class="btn-fill" style="margin-left: auto" @click="renameOrg">
                {{ $t('org.rename') }}
              </base-button>
            </div>
          </div>
        </card>
      </div>

      <div class="col-md-6">
        <card>
          <div>
            <h3 slot="header" class="title"> {{ $t('org.commonIds') }}</h3>
          </div>
          <div class="mt-3">
            <div>
              <base-input
                type="text"
                :label="$t('org.orgId')"
                :disabled="true"
                v-model="this.$store.getters.getCurrentOrganisation.id"
              >
              </base-input>
            </div>
            <div>
              <div style="display: flex; width: 100%;">
                <base-button class="btn-fill clipboard-btn" @click="copyToClipboard($store.getters.getCurrentOrganisation.id)">
                  {{ $t('general.clipboard') }}
                </base-button>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <card>
          <h3 slot="header" class="title"> {{ $t('org.leave') }}</h3>
          <p>Leave the <b>{{ this.$store.getters.getCurrentOrganisation.name }}</b> organisation.</p>
          <div style="display: flex; width: 100%;">
            <base-button type="warning" class="btn-fill" @click="leaveOrg">
              {{ $t('org.leaveBtn') }}
            </base-button>
          </div>
        </card>
      </div>
      <div class="col-md-6">
        <card>
          <h3 slot="header" class="title"> {{ $t('org.delete') }}</h3>
          <p>Delete the <b>{{ this.$store.getters.getCurrentOrganisation.name }}</b> organisation.</p>
          <div style="display: flex; width: 100%;">
            <base-button type="danger" class="btn-fill" @click="deleteOrg">
              {{ $t('org.deleteBtn') }}
            </base-button>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { refreshContext } from '../../utils';
import ClipboardJS from "clipboard";
import swal from 'sweetalert2';
import { mapMutations, mapGetters } from 'vuex';

export default {
  data() {
    return {
      name: this.$store.getters.getCurrentOrganisation.name
    };
  },
  mounted() {
    this.$store.subscribe((mutation, state) => {
        switch(mutation.type) {
          case 'switchOrganisation':
            this.name = this.$store.getters.getCurrentOrganisation.name
          break;
        } 
     })
  },
  methods: {
    ...mapMutations([
      'switchOrganisation', // map `this.increment()` to `this.$store.commit('increment')`
    ]),
    leaveOrg() {
      swal.fire({
        title: this.$t('search.popup.confrm'),
        text: this.$t('search.popup.cantRevert'),
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        confirmButtonText: 'Yes, leave organisation!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          let accessToken = localStorage.getItem("userToken");
          const fetchOptions = {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          };
          const apiUrl = this.$apiEndpoint + `/api/orgs/` + this.$store.getters.getCurrentOrganisation.id + `/users/` + this.$store.state.user.id;
          fetch(apiUrl, fetchOptions)
            .then((response) => {
              if (response.ok) {
                swal.fire({
                  title: this.$t('org.deleted'),
                  text: this.$t('org.youLeaved') + " " + this.name,
                  icon: 'success',
                  confirmButtonClass: 'btn btn-success btn-fill',
                  buttonsStyling: false
                });
                return response.json();
              } else if (response.status === 401) {
                this.logout();
                throw new Error("Accès non autorisé. Veuillez-vous connecter.");
              } else {
                response.json().then((errorResponse) => {
                  swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: this.$t("errors.backend."+errorResponse.detail),
                  });
                });
                throw new Error(errorResponse.detail);
              }
            })
            .catch((error) => {
              console.error("Error removing user:", error);
            });
          refreshContext(this.$store);
        }
      });
    },
    deleteOrg() {
      if (this.$store.getters.getCurrentOrganisation.is_default) {
        swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: this.$t('errors.backend.DeleteDefaultOrg'),
          customClass: {
            confirmButton: 'btn btn-danger btn-fill',
          },
          buttonsStyling: false,
        });
      } else {
        swal.fire({
          title: this.$t('search.popup.confrm'),
          text: this.$t('search.popup.cantRevert') + "\n" + this.$t('org.usersDeactivated'),
          icon: 'warning',
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn btn-success btn-fill',
            cancelButton: 'btn btn-danger btn-fill'
          },
          confirmButtonText: 'Yes, delete organisation!',
          buttonsStyling: false
        }).then(result => {
          if (result.value) {
            let accessToken = localStorage.getItem("userToken");
            const fetchOptions = {
              method: "DELETE",
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            };
            const apiUrl = this.$apiEndpoint + `/api/orgs/` + this.$store.getters.getCurrentOrganisation.id;
            fetch(apiUrl, fetchOptions)
              .then((response) => {
                if (response.ok) {
                  swal.fire({
                    title: this.$t('org.deleted'),
                    text: this.$t('org.youDeleted') + " " + this.$store.getters.getCurrentOrganisation.name,
                    icon: 'success',
                    confirmButtonClass: 'btn btn-success btn-fill',
                    buttonsStyling: false
                  });
                  return response.json();
                } else if (response.status === 401) {
                  this.logout();
                  throw new Error("Accès non autorisé. Veuillez-vous connecter.");
                } else {
                  throw new Error(response.json());
                }
              })
              .catch((error) => {
                console.error("Error removing org:", error);
                // const errorMessage = this.$t("errors.backend."+error.detail) || 'Unknown error occurred';
                let errorMessage = 'Unknown error occurred';
                if (this.$t("errors.backend."+error.detail) != "errors.backend."+error.detail) {
                  errorMessage = this.$t("errors.backend."+error.detail);
                }
                swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: errorMessage,
                  customClass: {
                    confirmButton: 'btn btn-danger btn-fill',
                  },
                  buttonsStyling: false,
                });
              });
            this.switchOrg(null);
            refreshContext(this.$store, true);
          }
        });
      }
    },
    async switchOrg(orgId) {
      try {
        await this.$store.dispatch('switchOrganisation', orgId);
        this.$notify({
          message:
            this.$t('general.switchOrg'),
          timeout: 3000,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'center',
          verticalAlign: 'top',
          type: 'success'
        });
      } catch (error) {
        console.error(error.message);
        // Display an error message to the user using your preferred method
      }
    },
    copyToClipboard(text) {
      const clipboard = new ClipboardJS(".clipboard-btn", {
        text: function () {
          return text;
        },
      });
      clipboard.on("success", function (e) {
        this.$notify({
          type: 'primary',
          message: "Copied to clipboard!",
          icon: 'tim-icons icon-satisfied'
        });
        console.log("Copied to clipboard:", e.text);
        e.clearSelection();
      });
      clipboard.on("error", function (e) {
        console.error("Error copying to clipboard:", e.text);
      });
    },
    renameOrg() {
      if (this.name == "") {
        swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: this.$t('errors.backend.EmptyOrgName'),
          customClass: {
            confirmButton: 'btn btn-danger btn-fill',
          },
          buttonsStyling: false,
        });
        this.name = this.$store.getters.getCurrentOrganisation.name;
      } else {
        let accessToken = localStorage.getItem("userToken");
        if (this.name != this.$store.getters.getCurrentOrganisation.name) {
          const fetchOptions = {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/json",
            },

            body: JSON.stringify({
              id: this.$store.getters.getCurrentOrganisation.id,
              name: this.name,
              is_default: this.$store.getters.getCurrentOrganisation.is_default,
              tokens_q: this.$store.getters.getCurrentOrganisation.tokens_q,
              tokens_e: this.$store.getters.getCurrentOrganisation.tokens_e
            }),
          };
          console.log(fetchOptions.body);
          const apiUrl = this.$apiEndpoint + `/api/orgs/`;
          fetch(apiUrl, fetchOptions)
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else if (response.status === 401) {
              this.logout();
              throw new Error("Accès non autorisé. Veuillez-vous connecter.");
            } else {
              response.json().then((errorResponse) => {
                swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: this.$t("errors.backend."+errorResponse.detail),
                });
              });
              throw new Error(errorResponse.detail);
            }
          })
          .then((data) => {
            console.log(data);
          })
          .then((error) => {
            console.log(error);
          });
          console.log(this.name);
        }
        refreshContext(this.$store);
      }
    },
    logout() {
      localStorage.removeItem('userToken'); // Adjust based on your storage method
      localStorage.removeItem('startDate');
      localStorage.removeItem('endDate');
      localStorage.removeItem('exclusions');
      this.$store.dispatch('logout'); // If using Vuex

      this.$router.push("/login");
    }
  }
};
</script>
<style>
input[disabled="disabled"] {
  color: white !important;
}

</style>
